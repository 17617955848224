<template>
  <v-row>
    <v-col>
    <v-row>
      <v-col cols="3">
        <date-picker
          :value="date"
          label="Datum"
          :dateFormatted="dateFormatted"
          @changeDate="changeDate"
          ></date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-center">
      <v-col cols="2" class="ml-1">
<v-card>
  <v-data-table
    :headers="routesHeaders"
    :items="routesTotalWeight"
    :options="{itemsPerPage:50}"
    hide-default-footer
  >
   <template v-slot:[`item.route`]="{item}">
<td><a @click="selectRoute(item.route)">{{item.route}}</a></td>
    </template>
    <template v-slot:[`item.totalWeight`]="{item}">
<td>{{item.totalWeight+"\xa0"}}kg</td>
    </template>
  </v-data-table>
</v-card>
      </v-col>
      <v-col cols="4" class="ml-1">
        <v-card>
           <v-card-title>Totaal: {{totalWeight.toFixed(2)}}kg</v-card-title>
          <v-card-title>
            Nog te sorteren
          </v-card-title>
          <v-data-table
            :headers="itemsHeaders"
            :items="fetchItems"
            :options="{itemsPerPage:50}"
            hide-default-footer
          >
            <template v-slot:[`item.amount`]="{item}">{{item.amount+"\xa0"+item.unit}}</template>
            <template v-slot:[`item.actions`]="{item}"><v-btn small @click="appendItem(item)">&#8594;</v-btn></template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="5" class="ml-1">
        <v-card min-height="300px">
          <v-row no-gutters>
            <v-col
              ><v-select
              class="select"
                dense
                :items="routes"
                v-model="selectedRoute"
                @change="resetLogisticItems"
                item-text="route"
                item-value="route"
                label="Route"
                height="50px"
                :menu-props="{closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }"
              >
              <template v-slot:item="{item}">
                <h3>{{item.route}}</h3>
                </template>
              </v-select
            ></v-col>
            <v-col
              ><v-select
              class="select"
                dense
                :items="vehicles"
                item-text="name"
                item-value="name"
                v-model="selectedVehicle"
                label="Voertuig"
                height="50px"
                :menu-props="{ closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }"
              >
              <template v-slot:item="{item}">
                <h3>{{item.name}}</h3>
                </template>
              </v-select
            ></v-col>
             <v-col
              ><v-select
              class="select"
                dense
                :items="drivers"
                item-text="name"
                item-value="name"
                v-model="selectedDriver"
                label="Chauffeur"
                height="50px"
                :menu-props="{ closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }"
              >
              <template v-slot:item="{item}">
                <h3>{{item.name}}</h3>
                </template>
              </v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col><h2>Gesorteerd</h2></v-col>
            <v-col><v-btn color="primary" :disabled="!this.selectedRoute||!this.sortedArray.length" @click="insertLogistic">Opslaan</v-btn></v-col>                   
          </v-row>
          <v-data-table
            :headers="itemsHeaders"
            :items="sortedArray"
            :options="{itemsPerPage:50}"
            hide-default-footer
          >
            <template v-slot:[`item.amount`]="{item}">{{item.amount+"\xa0"+item.unit}}</template>
            <template v-slot:[`item.actions`]="{item}"><v-btn small @click="prependItem(item)"> &#8592; </v-btn></template>
          </v-data-table>
          <!-- <v-btn color="primary" @click="printLogisticNative">Afdrukken</v-btn> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
      <v-card>
        <v-card-title class="d-flex justify-end"><v-btn color="primary" @click="printAllLogistics">Alles afdrukken</v-btn></v-card-title>        
        <v-data-table
          :headers="logisticsHeaders"
          :items="logistics"
          :options="{itemsPerPage:50}"
          hide-default-footer
        >
          <template v-slot:[`item.route`]="{item}">
            <a @click="openLogisticModal(item)">{{item.route}}</a>
          </template>
          <template v-slot:[`item.payload`]="{item}">
            {{item.payload}} kg
          </template>
          <template v-slot:[`item.actions`]="{item}"><v-icon @click="printLogistic(item)">mdi-printer</v-icon></template>
        </v-data-table>
      </v-card>
      </v-col>
    </v-row>
    <logistic-modal
    v-if="logisticModal"
    :logisticModal="logisticModal"
    :logistic="logistic"
    @delete-logistic="deleteLogistic"
    @close-logistic-modal="closeLogisticModal"
    >
    </logistic-modal>
    </v-col>
  </v-row>
</template>

<script>
import {printAllLogistics, printLogistic,printLogisticItems,printLogisticNative } from "../js/pdfController"
import logisticModal from "../components/logisticModal"
import datePicker from '@/components/datePicker.vue'
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "sortItems",
  data() {
    return {
      date: new Date(Date.now()).toISOString().split("T")[0],
      dateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      datePicker: false,
      logisticModal: false,
      logistic: {},
      logistics: [],
      vehicles: [],
      drivers: [],
      route: "",
      sortedArray: [],
      items: [],
      selectedRoute: "",
      selectedVehicle: "",
      selectedDriver: "",
      selectedCategory: "",
      itemsHeaders:[
    {text:"Artikel",value:"name"},
    {text:"Aantal",value:"amount"},  
    {text:"",value:"actions"},       
      ],
      routesHeaders:[
    {text:"Route",value:"route"},
    {text:"Gewicht",value:"totalWeight"},        
      ],
      logisticsHeaders:[
    {text:"Route",value:"route"},
    {text:"Voertuig",value:"vehicle"},
    {text:"Chauffeur",value:"driver"},
    {text:"Gewicht",value:"payload"},
    {text:"",value:"actions"},    
      ]
    }
  },
  methods: {
    async fetchLogistics(){
this.logistics= await fetchGET("fetchLogistics",{date:this.date})
    },
    async changeDate(date){
      this.date = date
      this.dateFormatted = this.formatDate(date)
      this.fetchLogistics()          
    },    
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    appendItem(item){
      let obj = {date: this.date,route: this.selectedRoute,itemNumber: item.itemNumber}
      this.$store.commit("appendLogisticItem",obj)
      this.sortedArray.push(item)      
    },
    prependItem(item){
let index = this.sortedArray.findIndex(item2=>item2.itemNumber===item.itemNumber)
      if(index>-1)this.sortedArray.splice(index,1)
let obj = {date: this.date,route: this.selectedRoute,itemNumber: item.itemNumber}
      this.$store.commit("prependLogisticItem",obj)
    },
    openLogisticModal(item){
      this.logistic = item
      this.logisticModal = true
    },
    closeLogisticModal(){
      this.logistic = {}
      this.logisticModal = false
    },
    printAllLogistics() {
      printAllLogistics(this.logistics)
      this.sortedArray=[]
    },
    printLogistic(logistic) {
      printLogistic(logistic)
    },
    printLogisticItems() {
      printLogisticItems(this.sortedArray, this.selectedRoute, this.selectedVehicle,this.selectedDriver)
      this.sortedArray=[]
    },
    printLogisticNative() {
      printLogisticNative(this.sortedArray, this.selectedRoute, this.selectedVehicle,this.selectedDriver)
      this.sortedArray=[]
    },
    async insertLogistic(){
      try {
        let items= this.sortedArray.map(item=>{
  return { 
    itemNumber: item.itemNumber,
    name: item.name,
    amount: item.amount,
    unit: item.unit,
    totalWeight: item.totalWeight,
  }
})
      let routesTotalWeight = this.routesTotalWeight.filter(route=>route.route==this.selectedRoute)
      let totalWeight = routesTotalWeight[0].totalWeight
      let logistic = {
        date: new Date(this.date),
        route: this.selectedRoute,
        vehicle: this.selectedVehicle,
        driver: this.selectedDriver,
        payload: totalWeight,
        items: [],
        orders: this.$store.state.orders.filter(order=>order.route===this.selectedRoute).map(order=>order.orderNumber)
      }
      let index = this.logistics.findIndex(log=>log.route==logistic.route)
      let response
      if(index>-1){
        logistic.items= [...items]        
        response = await fetchPOST("pushLogisticItems",logistic)
        this.logistics[index].route = this.selectedRoute
        this.logistics[index].vehicle = this.selectedVehicle
        this.logistics[index].driver = this.selectedDriver
        this.logistics[index].items.push(items)
      }
      else {
        logistic.items= [items]
        response = await fetchPOST("insertLogistic",logistic)
        logistic._id = response.insertedId
        this.logistics.push(logistic)
      }
      successHandler("Opgeslagen")
    this.sortedArray= []
      } catch (e) {
        errorHandler(e,"Fout bij opslaan")
      }      
    },
    selectRoute(route){
      this.resetLogisticItems()
      this.selectedRoute = route
    },
    async deleteLogistic(logistic){
try {
let response = await fetchPOST("deleteLogistic",logistic)
if(response.deletedCount===0) throw "deleteLogistic"
let index = this.logistics.findIndex(log=>log.route==logistic.route)
if(index>-1) this.logistics.splice(index,1)
this.resetLogistic(logistic)
  successHandler("Route verwijderd")
  this.logisticModal = false
} catch (e) {
  errorHandler(e,"Fout bij verwijderen")
}
    },
    resetLogistic(logistic){
this.$store.commit("resetLogistic",logistic)
    },
    resetLogisticItems(){
      let items = this.sortedArray.map(item=>item.itemNumber)
      let obj = {date: this.date,route: this.selectedRoute,items: items}
  this.$store.commit("resetLogisticItems",obj)
  this.sortedArray= []
    }
  },
  computed: {
    fetchItems() {
      let orders = JSON.parse(JSON.stringify(this.$store.state.orders.filter(order=>order.route==this.selectedRoute)))
      let flatten = orders.map(order => order.items.filter(item=>(item.category=="Kip"||item.category=="Overig")&&item.assigned==false)).flat()
       let items = Object.values(
        flatten.reduce(
          (a, c) => (
            (a[c.name] = a[c.name]
              ? ((a[c.name].amount = (a[c.name].amount*100 + c.amount*100)/100), a[c.name])
              : c),
            a
          ),
          {}
        )
      )
      return items.sort((a,b)=>a.sort-b.sort)
    },
    orderItems(){
      let orders = this.$store.state.orders.filter(order=>order.route==this.selectedRoute)
      let flatten = orders.map(order => order.items.filter(item=>item.category=="Kip").map(item => item)).flat()
      return flatten
    },
    routesTotalWeight(){
      let orders = this.$store.state.orders
      let routes = this.routes
      let map = routes.map(route=>{ return {route:route.route,totalWeight:Math.round(orders.filter(order=>order.route==route.route).reduce((acc,cur)=>acc+cur.totalWeight,0))}})
      return map
    },
    totalWeight(){
      let totalWeight= 0
      if(this.orderItems.length){
      totalWeight = this.orderItems.reduce((acc,cur)=>acc+parseFloat(cur.totalWeight),0)
      }
return totalWeight
    },
    routes() {
      return this.$store.getters.activeRoutes
    },
    categories(){
      return this.$store.state.settings.categories
    }
  },
  async mounted() {
    this.$store.dispatch("fetchGET",{type:"fetchOrders",data:{}})
    this.fetchLogistics()
    this.vehicles = await fetchGET("fetchVehicles",{})
    this.drivers = await fetchGET("fetchDrivers",{})
  },
  components: {logisticModal,datePicker} 
}
</script>

<style scoped>
::v-deep .v-select__selection--comma{
    overflow: visible !important;
}
.items {
  font-size: 30px;
}
.select{
  font-size: 30px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  font-size: 1.5rem;
}
</style>