var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"value":_vm.date,"label":"Datum","dateFormatted":_vm.dateFormatted},on:{"changeDate":_vm.changeDate}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-1",attrs:{"cols":"2"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.routesHeaders,"items":_vm.routesTotalWeight,"options":{itemsPerPage:50},"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.route",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.selectRoute(item.route)}}},[_vm._v(_vm._s(item.route))])])]}},{key:"item.totalWeight",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.totalWeight+"\xa0")+"kg")])]}}],null,true)})],1)],1),_c('v-col',{staticClass:"ml-1",attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("Totaal: "+_vm._s(_vm.totalWeight.toFixed(2))+"kg")]),_c('v-card-title',[_vm._v(" Nog te sorteren ")]),_c('v-data-table',{attrs:{"headers":_vm.itemsHeaders,"items":_vm.fetchItems,"options":{itemsPerPage:50},"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.amount+"\xa0"+item.unit))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.appendItem(item)}}},[_vm._v("→")])]}}],null,true)})],1)],1),_c('v-col',{staticClass:"ml-1",attrs:{"cols":"5"}},[_c('v-card',{attrs:{"min-height":"300px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-select',{staticClass:"select",attrs:{"dense":"","items":_vm.routes,"item-text":"route","item-value":"route","label":"Route","height":"50px","menu-props":{closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }},on:{"change":_vm.resetLogisticItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.route))])]}}]),model:{value:(_vm.selectedRoute),callback:function ($$v) {_vm.selectedRoute=$$v},expression:"selectedRoute"}})],1),_c('v-col',[_c('v-select',{staticClass:"select",attrs:{"dense":"","items":_vm.vehicles,"item-text":"name","item-value":"name","label":"Voertuig","height":"50px","menu-props":{ closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}})],1),_c('v-col',[_c('v-select',{staticClass:"select",attrs:{"dense":"","items":_vm.drivers,"item-text":"name","item-value":"name","label":"Chauffeur","height":"50px","menu-props":{ closeOnClick: true, closeOnContentClick: false, disableKeys: true, openOnClick: false, maxHeight: 500 }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedDriver),callback:function ($$v) {_vm.selectedDriver=$$v},expression:"selectedDriver"}})],1)],1),_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Gesorteerd")])]),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","disabled":!this.selectedRoute||!this.sortedArray.length},on:{"click":_vm.insertLogistic}},[_vm._v("Opslaan")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.itemsHeaders,"items":_vm.sortedArray,"options":{itemsPerPage:50},"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.amount+"\xa0"+item.unit))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.prependItem(item)}}},[_vm._v(" ← ")])]}}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.printAllLogistics}},[_vm._v("Alles afdrukken")])],1),_c('v-data-table',{attrs:{"headers":_vm.logisticsHeaders,"items":_vm.logistics,"options":{itemsPerPage:50},"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.route",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLogisticModal(item)}}},[_vm._v(_vm._s(item.route))])]}},{key:"item.payload",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payload)+" kg ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.printLogistic(item)}}},[_vm._v("mdi-printer")])]}}],null,true)})],1)],1)],1),(_vm.logisticModal)?_c('logistic-modal',{attrs:{"logisticModal":_vm.logisticModal,"logistic":_vm.logistic},on:{"delete-logistic":_vm.deleteLogistic,"close-logistic-modal":_vm.closeLogisticModal}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }