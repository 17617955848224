<template>
<v-dialog :value="logisticModal" persistent fullscreen>
    <v-card>
        <v-card-title>{{logistic.route}} - {{logistic.vehicle}} - {{logistic.driver}}</v-card-title>
        <v-card class="float-left" v-for="(item,index) in logistic.items" :key="index">
        <ul>
       <li class="pa-2" v-for="item2 in item" :key="item2.itemNumber">
        {{item2.name}} {{item2.amount}} {{item2.unit}}
       </li>
       </ul>
        </v-card>
    </v-card>
    <v-footer class="d-flex justify-center">
        <v-btn-toggle>        
       <v-btn color="primary" @click="openPromptModal">Verwijder</v-btn>
        <v-btn color="primary" @click="closeLogisticModal">Annuleer</v-btn>
        <v-btn color="primary" @click="updateLogistic">Opslaan</v-btn>        
        </v-btn-toggle>
    </v-footer>
    <prompt-modal
      v-if="promptModal"
        @confirm="deleteLogistic"
        @cancel="promptModal = false"
        :promptModal="promptModal"
        promptTitle="Verwijder route"
        promptText="Weet u zeker dat u deze route wilt verwijderen?"
    ></prompt-modal>
</v-dialog>
</template>

<script>
import promptModal from '@/components/promptModal.vue'
export default {
    name:"logisticModal",
    props:{
        logisticModal: Boolean,
        logistic: Object
    },
    data(){ 
        return {
promptModal: false
        }
    },
    methods:{
        openPromptModal(){
this.promptModal = true
        },
        updateLogistic(){
            this.$emit("update-logistic",this.logistic)
        },
        deleteLogistic(){
            this.$emit("delete-logistic",this.logistic)
        },
        closeLogisticModal(){
            this.$emit("close-logistic-modal")
        }
    },
    components: {
        promptModal,
    },

}
</script>

<style>

</style>